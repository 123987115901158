module.exports = (function(){

    //
    // DEBOUNCE --------------------------------------------------------------------------
    //
    // Debounce method to guarantee that a callback method will only ever
    // be executed once in a given timeframe (<-> browser resize event in IE which fires continuously)
    // http://www.paulirish.com/2009/throttled-smartresize-jquery-event-handler
    // http://davidwalsh.name/function-debounce
    //
    // Note: if Underscore/Lo-Dash is included in your project you can use _.debounce(callback, wait)
    // http://lodash.com/docs#debounce
    //

    var Debounce = function(callback, wait) {

        var timeout = null;

        return function()
        {
            var obj = this, args = arguments;

            var delayed = function()
            {
                callback.apply(obj, args);
                timeout = null;
            };

            if(timeout) clearTimeout(timeout);
            timeout = setTimeout(delayed, wait);
        };

    };

    return Debounce;

})();