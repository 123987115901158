window.$ = window.jQuery = require('jquery');
//window.FastClick = require('FastClick');

$(document)

    .ready(function(){

        require('RoyalSlider');
        require('semantic');

        var Resize = require('./resize.js');
        var imagesLoaded = require('imagesloaded');
        var slider = $('#full-width-slider');
        var realisationSlider = $('#realisationSlider');

        $('.ui.sidebar').sidebar('attach events', '#mobileMenuButton');

        $('.ui.dropdown').dropdown({
            on: 'hover',
            duration: '0'
        });

        //ROYALSLIDE ON HOME
        if(slider.length > 0){
            slider.royalSlider({
                arrowsNav: false,
                loop: true,
                addActiveClass:true,
                keyboardNavEnabled: true,
                controlsInside: false,
                imageScaleMode: 'fill',
                imageAlignCenter:true,
                arrowsNavAutoHide: true,
                autoScaleSlider: false,
                controlNavigation: 'none',
                thumbsFitInViewport: false,
                navigateByClick: true,
                startSlideId: 0,
                slidesSpacing: 0,
                transitionType:'fade',
                globalCaption: false,
                deeplinking: {
                    enabled: false,
                    change: false
                },
                autoPlay: {
                    // autoplay options go gere
                    enabled: true,
                    pauseOnHover: false,
                    delay : 3500
                }
            });
        }

        if(realisationSlider.length > 0){
            realisationSlider.royalSlider({
                arrowsNav: true,
                loop: false,
                keyboardNavEnabled: true,
                controlsInside: false,
                imageScaleMode: 'fit',
                imageAlignCenter: true,
                arrowsNavAutoHide: false,
                autoScaleSlider: true,
                controlNavigation: 'none',
                thumbsFitInViewport: false,
                navigateByClick: true,
                startSlideId: 0,
                autoPlay: false,
                transitionType:'move',
                globalCaption: false,
            });
        }

        // Foto grid
        function resizeImages(){

            // Store original image dimensions
            $('.photoset-item img').each(function () {
                $(this)
                    .data('org-width', $(this)[0].naturalWidth)
                    .data('org-height', $(this)[0].naturalHeight);
            });

            // Set photoset image size
            $('.photoset-row').each(function () {
                var $pi    = $(this).find('.photoset-item'),
                    cWidth = $(this).parent('.photoset').width();

                // Generate array containing all image aspect ratios
                var ratios = $pi.map(function () {
                    return $(this).find('img').data('org-width') / $(this).find('img').data('org-height');
                }).get();

                // Get sum of widths
                var sumRatios = 0, sumMargins = 0,
                    minRatio  = Math.min.apply(Math, ratios);
                for (var i = 0; i < $pi.length; i++) {
                    sumRatios += ratios[i]/minRatio;
                }

                $pi.each(function (){
                    sumMargins += parseInt($(this).css('margin-left')) + parseInt($(this).css('margin-right'));
                });

                // Calculate dimensions
                $pi.each(function (i) {
                    var minWidth = (cWidth - sumMargins)/sumRatios;
                    $(this).find('img')
                        .height(Math.floor(minWidth/minRatio))
                        .width(Math.floor(minWidth/minRatio) * ratios[i]);
                });
            });

        }

        // Check when images are loaded
        var imgLoad = imagesLoaded( "#home" );
        function onAlways( instance ) {
            resizeImages();
        }
        imgLoad.on( 'always', onAlways );

        // Resize
        Resize.onWindowResize(function(width, height) {
            // Set photoset image size
            resizeImages();
        });

        // Trigger images resize
        resizeImages();

        // Trigger resize
        $(window).trigger('resize');

    });